import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <iframe
          width="860"
          height="484"
          src="https://www.youtube.com/embed/_fLQWQxOSkY?si=Yccuk6YH8rgwwTab"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <p>પલિયડ ગામ દિવાળી ગરબા - મોટો ભાગ 2024</p>
      </header>
    </div>
  );
}

export default App;
